import axios from 'axios'

const instance  = axios.create({
  baseURL: process.env.NODE_ENV == 'development' ? 'http://127.0.0.1:8000/api' : 'https://voltage-plug-socket.com/api',
  timeout: 15000
})

function get(url,data) {
    return instance.get(url)      
}

function post(url,data) {
    return instance.get(url,data=data)      
}

export{
  get,post
}