import { createApp } from 'vue'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import VueLazyLoad from 'vue-lazyload'

import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp(App) 

app.use(ElementPlus).use(store).use(router).use(VueLazyLoad)
app.mount('#app')

