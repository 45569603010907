<template>
  
<div v-if="isArticle" class="article">
  <div class="title">
    {{ article.title }}
  </div>
  <div class="subtitle">
    {{ article.create_time }}
  </div>
  <div class="desp">
    {{ article.desp }}
  </div>
  <div v-html="article.content" class="content"></div>
</div>


<div v-else="isArticle" style="padding-bottom: 50px;">
  <div v-if="!is404">
    <span>
      <!-- 这是顶部图片 -->
      <section
        @click="refresh"
        id="header"
        :style="{ backgroundImage: `url(${cover.img})` }"
        v-for="cover in covers"
        :key="cover.img"
      >
        <div>
          <div class="title">{{ cover.title }}</div>
          <div class="sub-title">{{ cover.desp }}</div>
        </div>
      </section>

      <div id="body">
        <!-- 选择框 -->
        <div id="selection">
          <p>
            Select a location and destination country to see differences between
            voltage/frequency and plug types of them.
          </p>
          <!-- Only for PC -->
          <!-- v-if="!isMobile"  -->
          <div class="section-list">
            <section>
              <text>To</text>
              <el-autocomplete
                class="inline-input"
                clearable
                @keyup.enter.native="selectDestination"
                v-model="destination"
                :fetch-suggestions="querySearch"
                placeholder="Select your destination"
                @input="changeStyle('block', '.el-autocomplete-suggestion')"
                @keyup="changeStyle('block', '.el-autocomplete-suggestion')"
                @select="selectDestination"
                @clear="clearDestination"
              >
                <template #default="{ item }">
                  <!-- v-lazy="
                        require('@/static/img/flag/' +
                          item.flag.split('/').reverse()[0])
                      " -->
                  <div style="display: flex">
                    <img
                      style="width:32px;height:32px" 
                      v-lazy="item.flag"
                    />
                    <div class="content">
                      <span style="margin: 0 4px">{{ item.value }}</span>
                      <span style="margin: 0 4px">{{ item.voltage }}</span>
                      <span style="margin: 0 4px">{{ item.socketType }}</span>
                    </div>
                  </div>
                </template>
              </el-autocomplete>
            </section>

            <section v-if="countryNameList.includes(destination)">
              <text>From</text>
              <el-autocomplete
                class="inline-input"
                clearable
                v-model="location"
                :fetch-suggestions="querySearch"
                @keyup.enter.native="selectLocation"
                placeholder="Select your location"
                @select="selectLocation"
                @clear="clearLocation"
              >
                <template #default="{ item }">
                  <div style="display: flex">
                    <img 
                      style="width:32px;height:32px" 
                      v-lazy="item.flag"
                    />
                    <div class="content">
                      <span style="margin: 0 4px">{{ item.value }}</span>
                      <span style="margin: 0 4px">{{ item.voltage }}</span>
                      <span style="margin: 0 4px">{{ item.socketType }}</span>
                    </div>
                  </div>
                </template>
              </el-autocomplete>
            </section>

            <el-button
              v-if="location && destination"
              color="#2a3cbc"
              @click="compareBtn"
              >Compare</el-button
            >
            <el-button type="danger" plain @click="resetBtn">Reset</el-button>
          </div>

          <div class="target-list">
            <h2>Find Destination by Country or Region:</h2>
            <ul>
              <li>
                <a href="/#A">A</a>
              </li>
              <li>
                <a href="/#B">B</a>
              </li>
              <li>
                <a href="/#C">C</a>
              </li>
              <li>
                <a href="/#D">D</a>
              </li>
              <li>
                <a href="/#E">E</a>
              </li>
              <li>
                <a href="/#F">F</a>
              </li>
              <li>
                <a href="/#G">G</a>
              </li>
              <li>
                <a href="/#H">H</a>
              </li>
              <li>
                <a href="/#I">I</a>
              </li>
              <li>
                <a href="/#J">J</a>
              </li>
              <li>
                <a href="/#K">K</a>
              </li>
              <li>
                <a href="/#L">L</a>
              </li>
              <li>
                <a href="/#M">M</a>
              </li>
              <li>
                <a href="/#N">N</a>
              </li>
              <li>
                <a href="/#O">O</a>
              </li>
              <li>
                <a href="/#P">P</a>
              </li>
              <li>
                <a href="/#Q">Q</a>
              </li>
              <li>
                <a href="/#R">R</a>
              </li>
              <li>
                <a href="/#S">S</a>
              </li>
              <li>
                <a href="/#T">T</a>
              </li>
              <li>
                <a href="/#U">U</a>
              </li>
              <li>
                <a href="/#V">V</a>
              </li>
              <li>
                <a href="/#W">W</a>
              </li>
              <!-- <li>
                <a href="/#X">X</a>
              </li> -->
              <li>
                <a href="/#Y">Y</a>
              </li>              
              <li>
                <a href="/#Z">Z</a>
              </li>
            </ul>
          </div>
        </div>

        <div id="select-results">
          <!-- 没有输入目的国的时候显示 -->
          <!-- v-if="!destination_country" -->
          <div class="result-content" >
            <p>
              Are you planning to travel abroad? Check if you need a power plug
              adapter or voltage converter for the power sockets (outlets) used in
              that country. On this website you find information about the power
              plugs and sockets, electricity, voltage and frequency used in every
              country of the world. Select a country from the list above.
            </p>
            <img src="@/static/img/power-plugs-sockets-of-the-world.gif" />
          </div>

          <!-- 输入目的国的时候显示 -->
          <div class="result-content" v-if="destination_country" id="destination">
            <h2>Your Destination</h2>
            <div class="content">
              <section>
                In {{ destination_country.country_name }} the standard voltage is
                {{ destination_country.voltage }} and the frequency is
                {{ destination_country.frequence }}. 
                <template v-if="200< destination_country.voltage.replace('V','')">
                <!-- 如果目的国家电压在240伏，就显示下面 -->
                You can use your electric appliances in {{ destination_country.country_name }}
                if the standard voltage in your country is in between 220V - 240V
                (as is in the UK, Europe, Australia and most of Asia and Africa),
                Manufacturers take these small deviations into account. 
                If the standard voltage in your country is in the range of 100V - 127V
                (as is in the US, Canada and most South American countries), you
                need a voltage converter in {{ destination_country.country_name }}.
                You can also consider a combined power plug adapter/voltage converter.
                </template>
                <template v-else>
                <!-- 如果目的国家电压在120伏，就显示下面 -->
                You can use your electric appliances in {{ destination_country.country_name }}, 
                if the standard voltage in your country is in between 110V - 127V 
                (as is in the US, Canada and most South American countries). 
                Manufacturers take small deviations (plus or minus 5%) into account. 
                Be careful if you bring appliances from Japan (100V).
                If the standard voltage in your country is in the range of 220V- 240V 
                (as is in the UK, Europe, Asia, Africa and Australia), you 
                need a voltage converter in {{ destination_country.country_name }}.
                Some say you can carefully try to use your appliances in {{ destination_country.country_name }} without a converter. 
                Most likely they won't be damaged, but may not function optimally. 
                If you don't want to take any chances, use a converter. 
                </template>
              </section>
              <section>
                If the frequency in {{ destination_country.country_name }} ({{
                  destination_country.frequence
                }}) differs from the one in your country, it is not advised to use
                your appliances. But if there is no voltage difference, you could
                (at your own risk) try to use the appliance for a short time. Be
                especially careful with moving, rotating and time related
                appliances like clocks, shavers or electric fan heaters.
              </section>
              <section>
                To be sure, check the label on the appliance. Some appliances
                never need a converter. If the label states 'INPUT: 100-240V,
                50/60 Hz' the appliance can be used in all countries in the world.
                This is common for chargers of tablets/laptops, photo cameras,
                cell phones, toothbrushes, etc.
              </section>
            </div>
          </div>

          <!-- 输入 目的国 和 来源国 的时候显示比较结果 -->
          <div
            class="result-content"
            id="ComparisonResult_1670130578382"
            v-if="destination_country && location_country"
          >
            <h2>
              <span>🎉</span>
              Comparison Result
            </h2>

            <div
              v-if="destination_country && location_country"
              id="comparison-result"
            >
              <el-card shadow="hover" style="flex: 1">
                <section class="destination-country-intro">
                  <p>Destination</p>
                  <span>{{ destination_country.country_name }}</span>
                  <p>Frequency</p>
                  <span>{{ destination_country.frequence }}</span>
                  <p>Voltage</p>
                  <span>{{ destination_country.voltage }}</span>
                  <p>Power Plugs&Sockets</p>
                  <div class="imglist">
                    <div
                      v-for="(socket, index) in destination_country.socketType"
                      :key="socket.mainimg"
                    >
                      <img
                        v-lazy="socket.mainimg"
                        :alt="socket.name"
                        :key="index"
                      />
                      <span>Plug {{ socket.name }}</span>
                    </div>
                  </div>
                  <img
                    class="country"
                    style="width:30px;height:30px"
                    v-lazy="destination_country.flag"
                  />
                </section>
              </el-card>
              <!-- Only for PC -->
              <div v-if="!isMobile" class="divider">
                <p style="font-weight: bold">Alternatives</p>
                <img alt="" :src="require('@/static/img/divider.png')" />
                <!-- 如果没有适配插头，就不显示 -->
                <p
                  v-if="
                    destination_country.socketType.map((item) =>
                      item.extra_plug.map((item) => item.name)
                    )[0][0]
                  "
                >
                  {{
                    Array.from(
                      new Set(
                        destination_country.socketType
                          .map((plug) => plug.extra_plug)
                          .flat()
                          .map((v) => v.name)
                      )
                    ).join(", ")
                  }}
                </p>
                <p v-else>N/A</p>
              </div>
              <el-card
                shadow="hover"
                style="flex: 1"
                v-bind:style="{ marginTop: isMobile ? '1rem' : 'auto' }"
              >
                <section class="location-country-intro">
                  <p>Location</p>
                  <span>{{ location_country.country_name }}</span>
                  <p>Frequency</p>
                  <span>{{ location_country.frequence }}</span>
                  <p>Voltage</p>
                  <span>{{ location_country.voltage }}</span>
                  <p>Power Plugs&Sockets</p>
                  <div class="imglist">
                    <div
                      v-for="(socket, index) in location_country.socketType"
                      :key="socket.mainimg"
                    >
                      <img v-lazy="socket.mainimg" alt="" :key="index" />
                      <span style="text-align: center"
                        >Plug {{ socket.name }}</span
                      >
                    </div>
                  </div>
                  <img
                    style="width:30px;height:30px"
                    class="country"
                    v-lazy="location_country.flag"
                  />
                </section>
              </el-card>
              <!-- Only for Mobile -->
              <div v-if="isMobile" style="margin-top: 1rem">
                <p style="font-weight: bold">Alternatives</p>
                <!-- 如果没有适配插头，就不显示 -->
                <p
                  v-if="
                    destination_country.socketType.map((item) =>
                      item.extra_plug.map((item) => item.name)
                    )[0][0]
                  "
                >
                  {{
                    Array.from(
                      new Set(
                        destination_country.socketType
                          .map((plug) => plug.extra_plug)
                          .flat()
                          .map((v) => v.name)
                      )
                    ).join(", ")
                  }}
                </p>
                <p v-else>N/A</p>
              </div>
            </div>
          </div>

          <!-- Suggestions -->
          <div
            class="result-content"
            v-if="
              location_country &&
              destination_country &&
              (countryNameList.includes(location) ||
                countryNameList.includes(location && location[1]))
            "
          >
            <h2>
              <span>👩🏻‍🏫</span>
              Suggestions
            </h2>
            <!-- 是否需要电压转换器 -->
            <!-- Case 1: 电压差距在50之内，不需要电压转换器 -->
            <p
              v-if="
                Math.abs(
                  Number(String(location_country.voltage).split('V')[0]) -
                    Number(String(destination_country.voltage).split('V')[0])
                ) < 50
              "
            >
              You don't need a voltage converter, because the same voltages are
              found.
            </p>
            <!-- 电压差距在50之外，就需要电压转换器 -->
            <p v-else>You need a voltage converter.</p>

            <!-- Case 2: 频率差距在30之外 -->
            <p
              v-if="
                Math.abs(
                  Number(String(location_country.frequence).split('Hz')[0]) -
                    Number(String(destination_country.frequence).split('Hz')[0])
                ) >= 30
              "
            >
              Be extra careful with certain appliances because of the difference
              in frequency.
            </p>

            <div
              v-for="(type, index) in location_country.socketType"
              :key="index"
            >
              <!-- 现在遍历的是出发国的{{type.name}}， -->
              <!-- 出发国的某一个插头在目的国的插座插头范围内 -->
              <ul
                v-if="
                  destination_country &&
                  destination_country.socketType
                    .map((item) => item.name)
                    .includes(type.name)
                "
              >
                <li>
                  <!-- {{type.name}}在插座插头范围内 <br> -->
                  You can take your appliances with
                  <el-popover
                    placement="top-start"
                    :title="`Plug ${type.name}`"
                    :width="200"
                    trigger="hover"
                  >
                    <img class="country" v-lazy="type.mainimg" />
                    <template #reference>
                      <span>Plug {{ type.name }}</span>
                    </template>
                  </el-popover>
                  to {{ destination_country.country_name }}
                </li>
              </ul>

              <!-- 出发国的某一个插头在目的国的适配插头范围内 -->
              <div
                v-else-if="
                  destination_country.socketType
                    .map((item) => item.extra_plug.map((itemmm) => itemmm.name))
                    .flat()
                    .includes(type.name)
                "
              >
                <ul
                  v-for="(i, index) in destination_country.socketType.map(
                    (item) => item.extra_plug.map((itemmm) => itemmm.name)
                  )"
                  :key="index"
                >
                  <li v-if="i.includes(type.name)">
                    <!-- {{type.name}}在名字是{{destination_country.socketType[index].name}}的插座的适配插头里面 <br> -->
                    You can take your appliances with
                    <el-popover
                      placement="top-start"
                      :title="`Plug ${type.name}`"
                      :width="200"
                      trigger="hover"
                    >
                      <img class="country" v-lazy="type.mainimg" />
                      <template #reference>
                        <span>Plug {{ type.name }}</span>
                      </template>
                    </el-popover>
                    to
                    {{ destination_country.country_name }}, because the Socket
                    alternative
                    <el-popover
                      placement="top-start"
                      :title="`Plug ${type.name}`"
                      :width="200"
                      trigger="hover"
                    >
                      <img class="country" v-lazy="type.mainimg" />
                      <template #reference>
                        <span>Plug {{ type.name }}</span>
                      </template>
                    </el-popover>
                    can work well with the
                    <el-popover
                      placement="top-start"
                      :title="`Socket ${destination_country.socketType[index].name}`"
                      :width="200"
                      trigger="hover"
                    >
                      <img
                        class="country"
                        v-lazy="destination_country.socketType[index].mainimg"
                      />
                      <template #reference>
                        <span>
                          Socket
                          {{ destination_country.socketType[index].name }}</span
                        >
                      </template>
                    </el-popover>
                  </li>
                </ul>
              </div>

              <!-- 出发国的某一个插头即不在目的国的适配插头范围内也不在插座插头范围内 -->
              <ul v-else>
                <li>
                  Your appliances with
                  <el-popover
                    placement="top-start"
                    :title="`Plug ${type.name}`"
                    :width="200"
                    trigger="hover"
                  >
                    <img class="country" v-lazy="type.mainimg" />
                    <template #reference>
                      <span>Plug {{ type.name }}</span>
                    </template>
                  </el-popover>
                  don't fit
                  <span
                    v-for="(type, index) in destination_country.socketType"
                    :key="type.name + index"
                  >
                    <el-popover
                      placement="top-start"
                      :title="`Socket ${type.name}`"
                      :width="200"
                      trigger="hover"
                    >
                      <img class="country" v-lazy="type.mainimg" />
                      <template #reference>
                        <span>{{ `Socket ${type.name}` }}</span>
                      </template> </el-popover
                    >,
                  </span>
                  so you need prepare the plug Converter in
                  {{ destination_country.country_name }}
                  <!-- {{ type.name }}即不在目的国的适配插头范围内也不在插座插头范围内 <br> -->
                </li>
              </ul>
            </div>
          </div>

          <!-- Ads -->
          <div id="ad-section">
            <div class="ad" v-for="(ad, index) in ads1" :key="ad.id">
              <div
                class="ad-wrapper"
                :style="{ backgroundImage: 'url(' + ad.img + ')' }"
                v-if="!adClearFlags1[index][ad.id]"
              >
                <a :href="ad.linkto" :alt="ad.title" target="_blank">
                  <div class="title">{{ ad.title }}</div>
                  <div class="desp">—— {{ ad.desp }}</div>
                </a>
                <span
                  @click="adClearFlags1[index][ad.id] = true"
                  class="clear-icon"
                  >&#215;</span
                >
              </div>
            </div>
          </div>

        </div>

        <!-- 目的国存在 -->
        <div
          id="pluglist"
          v-if="
            // destination_country && (countryNameList.includes(destination) || countryNameList.includes(destination && destination[1]))
            countryNameList.includes(destination)
          "
        >
          <h2>Plugs Details in {{ destination_country.country_name }}</h2>
          <el-collapse v-model="activeNames" accordion>
              <el-collapse-item
                v-for="(plug, index) in plugList"
                :key="index"
                :title="plug ? 'Plug '+plug.name:''"
                :name="index"
              >
              <div class="plugdesp" v-if="plug">{{ plug.desp }}</div>
              <div class="imglist" v-if="plug">
                <img
                  v-lazy="imgitem.img"
                  alt=""
                  v-for="(imgitem, index) in plug.extra_imgs"
                  :key="index"
                />
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>

        <!-- 目的国不存在 -->
        <div
          id="pluglist"
          v-if="
            !(
              countryNameList.includes(destination) ||
              countryNameList.includes(destination && destination[1])
            )
          "
        >
          <h2>About Plug Types</h2>
          <el-collapse v-model="activeNames" accordion>
            <el-collapse-item
              v-for="(plug, index) in plugList"
              :key="index"
              :title="plug ? 'Plug '+plug.name:''"
              :name="index"
            >
            <div class="plugdesp">{{ plug.desp }}</div>
              <!-- <template #title>
                <div class="plugItem">
                  <div class="plugdesp">{{ plug.name }}</div>
                  <div class="plugdesp">{{ plug.desp }}</div>
                </div>
              </template> -->
              
              <div class="imglist">
                <img
                  v-lazy="imgitem.img"
                  v-for="(imgitem, index) in plug.extra_imgs"
                  :key="index"
                />
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>

        <!-- Ads -->
        <div id="ad-section">
          <div class="ad" v-for="(ad, index) in ads2" :key="ad.id">
            <div
              class="ad-wrapper"
              :style="{ backgroundImage: 'url(' + ad.img + ')' }"
              v-if="!adClearFlags2[index][ad.id]"
            >
              <a :href="ad.linkto" :alt="ad.title" target="_blank">
                <div class="title">{{ ad.title }}</div>
                <div class="desp">——— {{ ad.desp }}</div>
              </a>
              <span @click="adClearFlags2[index][ad.id] = true" class="clear-icon"
                >&#215;</span
              >
            </div>
          </div>
        </div>
        
        <!-- 主页随机挑选7篇文章展示 -->
        <div
          id="pluglist" 
          class="article"
          v-if="listData.length==0"
        >
          <h2>Articles</h2>
          <el-collapse v-model="aarctiveNames" accordion>
            <el-collapse-item
              v-for="(article, index) in listDataAll"
              :key="index"
              :name="index"
            >    
              <template #title>
                <div class="navTitle">
                  <div class="left">
                    <h3>
                      {{ article.title }}
                    </h3>
                    <div>
                      {{ article.desp }}
                    </div>
                  </div>
                  <div class="imglist"><img v-lazy="article.img"/> </div>
                </div>
              </template>
              <!-- <div class="plugdesp">{{ article.desp }}</div> -->
              <div v-html="article.content"></div>
            </el-collapse-item>
          </el-collapse>
        </div>

        <!-- 目的国家的相关文章展示 -->
        <div
          id="pluglist" 
          class="article"
          v-if="listData.length>0"
        >
          <h2>Articles About {{ destination_country.country_name }}</h2>
          <el-collapse v-model="arctiveNames" accordion>
            <el-collapse-item
              v-for="(article, index) in listData"
              :key="index"
              :name="index"
            >    
              <template #title>
                <div class="navTitle">
                  <div class="left">
                    <h3>
                      {{ article.title }}
                    </h3>
                    <div>
                      {{ article.desp }}
                    </div>
                  </div>
                  <div class="imglist"><img v-lazy="article.img"/> </div>
                </div>
              </template>
              <!-- <div class="plugdesp">{{ article.desp }}</div> -->
              <div v-html="article.content"></div>
            </el-collapse-item>
          </el-collapse>
        </div>

        <div class="destination-list">
          <h2>Where do you want to go?</h2>
          <div class="country-list">
            <p id="A">A</p>
            <div class="item">
              <a class="country-item" :href="item.country_name.replaceAll(' ','_')+'#destination'" v-for="(item,index) in countryList.filter(i=>i.country_name.startsWith('A'))">
                <img :src="item.flag" alt="">
                <span>{{ item.country_name }}</span>
              </a>
            </div>
            <p id="B">B</p>
            <div class="item">
              <a class="country-item" :href="item.country_name.replaceAll(' ','_')+'#destination'" v-for="(item,index) in countryList.filter(i=>i.country_name.startsWith('B'))">
                <img :src="item.flag" alt="">
                <span>{{ item.country_name }}</span>
              </a>
            </div>
            <p id="C">C</p>
            <div class="item">              
              <a class="country-item" :href="item.country_name.replaceAll(' ','_').replace(' ','_')+'#destination'" v-for="(item,index) in countryList.filter(i=>i.country_name.startsWith('C'))">
                <img :src="item.flag" alt="">
                <span>{{ item.country_name }}</span>
              </a>   
            </div>
            <p id="D">D</p>
            <div class="item">               
              <a class="country-item" :href="item.country_name.replaceAll(' ','_')+'#destination'" v-for="(item,index) in countryList.filter(i=>i.country_name.startsWith('D'))">
                <img :src="item.flag" alt="">
                <span>{{ item.country_name }}</span>
              </a> 
            </div>
            <p id="E">E</p>
            <div class="item">  
              <a class="country-item" :href="item.country_name.replaceAll(' ','_')+'#destination'" v-for="(item,index) in countryList.filter(i=>i.country_name.startsWith('E'))">
                <img :src="item.flag" alt="">
                <span>{{ item.country_name }}</span>
              </a>   
            </div>
            <p id="F">F</p>
            <div class="item">
              <a class="country-item" :href="item.country_name.replaceAll(' ','_')+'#destination'" v-for="(item,index) in countryList.filter(i=>i.country_name.startsWith('F'))">
                <img :src="item.flag" alt="">
                <span>{{ item.country_name }}</span>
              </a> 
            </div>
            <p id="G">G</p>
            <div class="item">  
              <a class="country-item" :href="item.country_name.replaceAll(' ','_')+'#destination'" v-for="(item,index) in countryList.filter(i=>i.country_name.startsWith('G'))">
                <img :src="item.flag" alt="">
                <span>{{ item.country_name }}</span>
              </a> 
            </div>
            <p id="H">H</p>
            <div class="item">  
              <a class="country-item" :href="item.country_name.replaceAll(' ','_')+'#destination'" v-for="(item,index) in countryList.filter(i=>i.country_name.startsWith('H'))">
                <img :src="item.flag" alt="">
                <span>{{ item.country_name }}</span>
              </a>   
            </div>
            <p id="I">I</p>
            <div class="item">
              <a class="country-item" :href="item.country_name.replaceAll(' ','_')+'#destination'" v-for="(item,index) in countryList.filter(i=>i.country_name.startsWith('I'))">
                <img :src="item.flag" alt="">
                <span>{{ item.country_name }}</span>
              </a> 
            </div>
            <p id="J">J</p>
            <div class="item">  
              <a class="country-item" :href="item.country_name.replaceAll(' ','_')+'#destination'" v-for="(item,index) in countryList.filter(i=>i.country_name.startsWith('J'))">
                <img :src="item.flag" alt="">
                <span>{{ item.country_name }}</span>
              </a>  
            </div>
            <p id="K">K</p>
            <div class="item"> 
              <a class="country-item" :href="item.country_name.replaceAll(' ','_')+'#destination'" v-for="(item,index) in countryList.filter(i=>i.country_name.startsWith('K'))">
                <img :src="item.flag" alt="">
                <span>{{ item.country_name }}</span>
              </a>  
            </div>
            <p id="L">L</p>
            <div class="item">                         
              <a class="country-item" :href="item.country_name.replaceAll(' ','_')+'#destination'" v-for="(item,index) in countryList.filter(i=>i.country_name.startsWith('L'))">
                <img :src="item.flag" alt="">
                <span>{{ item.country_name }}</span>
              </a>  
            </div>
            <p id="M">M</p>
            <div class="item">   
              <a class="country-item" :href="item.country_name.replaceAll(' ','_')+'#destination'" v-for="(item,index) in countryList.filter(i=>i.country_name.startsWith('M'))">
                <img :src="item.flag" alt="">
                <span>{{ item.country_name }}</span>
              </a>  
            </div>
            <p id="N">N</p>
            <div class="item">   
              <a class="country-item" :href="item.country_name.replaceAll(' ','_')+'#destination'" v-for="(item,index) in countryList.filter(i=>i.country_name.startsWith('N'))">
                <img :src="item.flag" alt="">
                <span>{{ item.country_name }}</span>
              </a>  
            </div>
            <p name="O">O</p>
            <div class="item">   
              <a class="country-item" :href="item.country_name.replaceAll(' ','_')+'#destination'" v-for="(item,index) in countryList.filter(i=>i.country_name.startsWith('O'))">
                <img :src="item.flag" alt="">
                <span>{{ item.country_name }}</span>
              </a>  
            </div>
            <p id="P">P</p>
            <div class="item">   
              <a class="country-item" :href="item.country_name.replaceAll(' ','_')+'#destination'" v-for="(item,index) in countryList.filter(i=>i.country_name.startsWith('P'))">
                <img :src="item.flag" alt="">
                <span>{{ item.country_name }}</span>
              </a> 
            </div>
            <p id="Q">Q</p>
            <div class="item">    
              <a class="country-item" :href="item.country_name.replaceAll(' ','_')+'#destination'" v-for="(item,index) in countryList.filter(i=>i.country_name.startsWith('Q'))">
                <img :src="item.flag" alt="">
                <span>{{ item.country_name }}</span>
              </a>  
            </div>
            <p id="R">R</p>
            <div class="item">   
              <a class="country-item" :href="item.country_name.replaceAll(' ','_')+'#destination'" v-for="(item,index) in countryList.filter(i=>i.country_name.startsWith('R'))">
                <img :src="item.flag" alt="">
                <span>{{ item.country_name }}</span>
              </a>  
            </div>
            <p id="S">S</p>
            <div class="item">   
              <a class="country-item" :href="item.country_name.replaceAll(' ','_')+'#destination'" v-for="(item,index) in countryList.filter(i=>i.country_name.startsWith('S'))">
                <img :src="item.flag" alt="">
                <span>{{ item.country_name }}</span>
              </a>     
            </div>
            <p id="T">T</p>
            <div class="item">
              <a class="country-item" :href="item.country_name.replaceAll(' ','_')+'#destination'" v-for="(item,index) in countryList.filter(i=>i.country_name.startsWith('T'))">
                <img :src="item.flag" alt="">
                <span>{{ item.country_name }}</span>
              </a>   
            </div>
            <p id="U">U</p>
            <div class="item">  
              <a class="country-item" :href="item.country_name.replaceAll(' ','_')+'#destination'" v-for="(item,index) in countryList.filter(i=>i.country_name.startsWith('U'))">
                <img :src="item.flag" alt="">
                <span>{{ item.country_name }}</span>
              </a>  
            </div>
            <p id="V">V</p>
            <div class="item">   
              <a class="country-item" :href="item.country_name.replaceAll(' ','_')+'#destination'" v-for="(item,index) in countryList.filter(i=>i.country_name.startsWith('V'))">
                <img :src="item.flag" alt="">
                <span>{{ item.country_name }}</span>
              </a> 
            </div>
            <p id="W">W</p>
            <div class="item">    
              <a class="country-item" :href="item.country_name.replaceAll(' ','_')+'#destination'" v-for="(item,index) in countryList.filter(i=>i.country_name.startsWith('W'))">
                <img :src="item.flag" alt="">
                <span>{{ item.country_name }}</span>
              </a> 
            </div>
            <!-- <p id="X">X</p>
            <div class="item">    
              <div class="country-item" :href="item.country_name.replaceAll(' ','_')+'#destination'" v-for="(item,index) in countryList.filter(i=>i.country_name.startsWith('X'))">
                <img :src="item.flag" alt="">
                <span>{{ item.country_name }}</span>
              </div> 
            </div> -->
            <p id="Y">Y</p>
            <div class="item">    
              <a class="country-item" :href="item.country_name.replaceAll(' ','_')+'#destination'" v-for="(item,index) in countryList.filter(i=>i.country_name.startsWith('Y'))">
                <img :src="item.flag" alt="">
                <span>{{ item.country_name }}</span>
              </a>     
            </div>
            <p id="Z">Z</p>
            <div class="item">
              <a class="country-item" :href="item.country_name.replaceAll(' ','_')+'#destination'" v-for="(item,index) in countryList.filter(i=>i.country_name.startsWith('Z'))">
                <img :src="item.flag" alt="">
                <span>{{ item.country_name }}</span>
              </a> 
            </div>
          </div>
        </div>

      </div>
      <el-backtop :visibility-height="520" />
    </span>

  </div>
  <div v-if="is404">
    <div class="err404" >
        <img src="./static/img/404.gif" @click="gohome" id="img404">
    </div>
  </div>
</div>
</template>
<script>
import {
  ELautocomplete,
  ElButton,
  ElBacktop,
  ElCard,
  ElPopover,
  ElCollapse,
  ElCollapseItem,
  ElCascader,
  ELTable,
  ElTableColumn
} from "element-plus";

// import list from 'ant-design-vue/lib/list'; // 加载 JS
// import 'ant-design-vue/lib/list/style/css'; // 加载 CSS
import {List} from 'ant-design-vue';
import 'ant-design-vue/lib/list/style/css'; // 加载 CSS
import { get, post } from "@/http/api";
import { truncate } from "fs";
import { defineComponent, onMounted, ref, nextTick } from 'vue';

export default {
  name: "App",
  provide() {
    return {
      reload: this.reload,
    };
  },
  components: {
    AList:List,
    AListItem:List.Item,
    AListItemMeta:List.Item.Meta,

    ELautocomplete,
    ElButton,
    ElBacktop,
    ElCard,
    ElPopover,
    ElCollapse,
    ElCollapseItem,
    ElCascader,
    ELTable,
    ElTableColumn
  },
  data() {
    return {
      is404: false,
      countryList: Object.keys(localStorage).includes("countryList")
        ? JSON.parse(localStorage.getItem("countryList"))
        : [],
      countryNameList: Object.keys(localStorage).includes("countryList")
        ? JSON.parse(localStorage.getItem("countryList")).map(
            (item) => item.country_name
          )
        : [],
      plugListTMP: "",
      plugList: [],
      plugListOrigin: [],
      destination: "",
      location: "",
      destination_country: "",
      location_country: "",
      ads1: "", // 广告
      ads2: "", // 广告
      covers: localStorage.getItem("covers")
        ? JSON.parse(localStorage.getItem("covers"))
        : "", //首页顶部封面
      adClearFlags1: [], // 广告是否清除标志
      adClearFlags2: [], // 广告是否清除标志
      activeNames: ["1"],
      arctiveNames:["1"],
      aarctiveNames:["1"],
      timer: 0,
      // 是否为移动端
      isMobile: false,
      // 移动端级联选择器
      letters: [],
      options: [],
      plugShow:true,
      article:{},
      isArticle:false,
      listData :[],
      listDataAll:[],
      actions :[{
        type: 'StarOutlined',
        text: '156',
      }, {
        type: 'LikeOutlined',
        text: '156',
      }, {
        type: 'MessageOutlined',
        text: '2',
      }],
      tableData:[]
    }
  },
  methods: {
    gohome(){
      window.location.href = '/'
    },
    goarticle(url){
        console.log('url:',url)
        window.open('/article/'+url)
        // get(`/articleone/${url}/`).then(res => {
        //   console.log(res)
        //   this.isArticle=true
        //   this.article=res.data
        //   window.history.replaceState(
        //     { destination_country: res.data },
        //     "",
        //     "/article/"+url
        //   )
        // })
    },
    getDataAll7(){
      get('/articleall/').then(res=>{
        console.log('articleall:',res.data)
        this.listDataAll = res.data
      })
    },
    // 返回搜索结果
    querySearch(queryString, cb) {
      console.log("querySearch: ", queryString);
      const results = queryString
        ? this.countryList.filter((item) =>
            item["country_name"]
              .toLowerCase()
              .includes(queryString.toLowerCase())
          )
        : this.countryList.filter((v) => {
            if (this.destination !== "") {
              return (
                this.destination !== "" &&
                v["country_name"] !== this.destination
              );
            }
            if (this.location !== "") {
              return v["country_name"] !== this.location;
            }
            return true;
          });

      const res = results.map((item, index) => {
        let plugTypeNameList = [];
        item["socketType"].map((item) => plugTypeNameList.push(item.name));
        return {
          value: item["country_name"],
          flag: item["flag"],
          voltage: item["voltage"],
          socketType: String(plugTypeNameList),
          index: index,
          id:item["id"]
        };
      });
      cb(res);
    },
    // 选择目的地
    selectDestination(query, shoudEmitRouterChange = true) {
      // console.log("selectDestination query: ", query.id);
      let value = this.destination ? this.destination:query.value
      console.log("value:",value)
      // let vv = this.countryNameList.filter(item => item.replaceAll(' ','_').toLowerCase() == value.toLowerCase())
      let vv = this.countryList.find(item=>item.country_name.replaceAll(' ','_').toLowerCase() == value.toLowerCase())
      if (!vv) vv = this.countryList.find(item=>item.country_name.toLowerCase() == value.toLowerCase())
      console.log("find country name 1: ",vv)
      if (!vv) return;
      this.destination = vv.country_name
      get(`/countryone/${vv.country_name}/`).then((res) => {
        console.log("selectDestination: ", res.data);
        // 目的国家
        this.destination_country = res.data;
        // history router changes
        shoudEmitRouterChange &&
          window.history.replaceState(
            { destination_country: res.data },
            "",
            "./" + res.data.country_name.replaceAll(" ", "_")
          );
        let plugListTMP = [];
        // 选择目的地之后，就筛选出对应的插头
        res.data.socketType.forEach((element) => {
          plugListTMP.push(
            this.plugListOrigin.find((i) => i["name"] == element["name"])
          );
        });
        this.plugList = plugListTMP;
        this.changeStyle("none", ".el-autocomplete-suggestion");    
      });
      // let obj = this.countryList.find(item=>item.country_name == segs[0])
      let query_id = query.id ? query.id : vv.id
      if(query_id){
        get(`/article/?countryid=${query_id}`).then(res => {
            console.log('选择目的地:',res)
            this.listData = res.data
        });
      }
    },
    clearDestination() {
      this.destination = "";
      this.destination_country = null;
      this.clearLocation();
      window.history.replaceState(null, "", "/");
      this.plugList = this.plugListOrigin;
      window.location.reload()
    },
    selectLocation(query, shoudEmitRouterChange = true) {
      console.log("selectLocation query: ", query);
      let value = this.location ? this.location:query.value
      console.log("value:",value)
      // let vv = this.countryNameList.filter(item => item.replaceAll(' ','_').toLowerCase() == value.toLowerCase())
      let vv = this.countryList.find(item=>item.country_name.replaceAll(' ','_').toLowerCase() == value.toLowerCase())
      if (!vv) vv = this.countryList.find(item=>item.country_name.toLowerCase() == value.toLowerCase())
      console.log("find country name 2: ",vv)
      this.location = vv.country_name
      get(`/countryone/${vv.country_name}/`).then((res) => {
        console.log("selectLocation: ", res.data);
        // 出发国家数据
        this.location_country = res.data;
        // history router changes
        shoudEmitRouterChange &&
          window.history.replaceState(
            { location_country: res.data },
            "",
            `${window.location.pathname}/${encodeURI(
              res.data.country_name.replaceAll(" ", "_")
            )}`
          );
      });
    },
    clearLocation() {
      this.location = "";
      this.location_country = null;
      const { pathname } = window.location;
      const rawSegs = pathname.split("/");
      const segs = Array.isArray(rawSegs) ? rawSegs.filter(Boolean) : [];
      if (segs.length <= 2) {
        segs.pop();
      }
      window.history.replaceState(null, "", `/${segs.join("/")}`);
    },
    // 重置按钮
    resetBtn() {
      this.location = "";
      this.destination = "";
      window.history.replaceState(null, "", "/");
      window.location.reload();
    },
    // Anchor
    compareBtn() {
      location.href = "#ComparisonResult_1670130578382";
    },
    // 刷新页面
    refresh() {
      // window.location.reload()
      this.$router.go(0);
    },
    resizeFunc() {
      if (
        document.activeElement.tagName == "INPUT" ||
        document.activeElement.tagName == "TEXTAREA"
      ) {
        this.timer = window.setTimeout(function () {
          document.activeElement.scrollIntoViewIfNeeded();
        }, 0);
      }
    },
    /**
     * 移动端针对搜索进行适配
     * 判断设备类型
     */
    checkIsMobile() {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      console.log("Device:", flag ? flag.toString() : "PC");
      if (flag) {
        this.isMobile = true;
      }
      localStorage.setItem("isMobile", flag);
    },
    handleDestinationChange(selected) {
      if (!selected) return this.clearDestination();
      this.selectDestination({ value: this.destination[1] });
    },
    // handleLocationChange
    handleLocationChange(selected) {
      if (!selected) return this.clearLocation();
      this.selectLocation({ value: this.location[1] });
    },
    // 点击下方目的国家
    handleCurrentChange(query,e){
      console.log(e)
      console.log(query)
      this.destination = query.country_name;
      this.selectDestination({ value: query.country_name }, false);

      get(`/countryone/${query.country_name}/`).then((res) => {
        console.log("selectDestination: ", res.data);
        // 目的国家
        this.destination_country = res.data;
        // history router changes
        window.history.replaceState(
          { destination_country: res.data },
          "",
          "./" + res.data.country_name.replaceAll(" ", "_")
        );
        let plugListTMP = [];
        // 选择目的地之后，就筛选出对应的插头
        res.data.socketType.forEach((element) => {
          plugListTMP.push(
            this.plugListOrigin.find((i) => i["name"] == element["name"])
          );
        });
        this.plugList = plugListTMP;
      });
      // let obj = this.countryList.find(item=>item.country_name == segs[0])
      // let query_id = query.id ? query.id : obj.id
      if(query.id){
        get(`/article/?countryid=${query.id}`).then(res => {
            console.log('选择目的地:',res)
            this.listData = res.data
        });
      }
      window.scrollTo({
          top:1200,
          behavior:"smooth"
      })
    },
    // check URL
    checkCurrentURL() {
      const { hash, pathname } = window.location;
      console.log("pathname::",pathname,hash)

      const rawSegs = pathname.split("/");
      const segs = Array.isArray(rawSegs) ? rawSegs.filter(Boolean) : [];

      console.log("segs",segs)
      // ['Algeria', 'Antigua_and_Barbuda'] ==> ['Algeria', 'Antigua and Barbuda']
      segs.forEach((_seg, idx) => {
        segs[idx] = segs[idx].replaceAll("_", " ");
      });
      if(!segs[0]){
          this.is404 = false
          // 获取插座类型
          get("/pluglist/").then((res) => {
            console.log("getPluglist:", res.data);
            this.plugListOrigin = res.data;
            this.plugList = res.data;
          });          
          this.getDataAll7();
      }else{
      // ignore unexpected URL seg
      if (segs.length == 2) {
        // 第一个参数是article，这就是跳转到article页面 
        if(segs[0] == 'article'){
          this.isArticle = true
          console.log('url:',segs[0])
          let url = segs[1]
          console.log(segs[1])
          get(`/articleone/${url}/`).then(res => {
            console.log(res)
            this.isArticle=true
            this.article=res.data
          })
        }

        const existedDestinationCountry = this.countryNameList.includes(segs[0]);
        const existedLocationCountry = this.countryNameList.includes(segs[1]);
        console.log("existedLocationCountry:",existedLocationCountry)
        // 目的国和出发国都存在
        if (existedDestinationCountry && existedLocationCountry){
          this.is404 = false
          this.destination = segs[0];
          this.selectDestination({ value: segs[0] }, false);
          this.location = segs[1];
          this.selectLocation({ value: segs[1] }, false);

          let obj = this.countryList.find(item=>item.country_name == segs[0])
            get(`/article/?countryid=${obj.id}`).then(res => {
                  console.log('resss:',res)
                  this.listData = res.data
          });

          // 获取插座类型
          get("/pluglist/").then((res) => {
            console.log("getPluglist:", res.data);
            this.plugListOrigin = res.data;
            this.plugList = res.data;

            get(`/countryone/${segs[0]}/`).then((res) => {
              console.log("selectDestination: ", res.data);
              // 目的国家
              this.destination_country = res.data;

              let plugListTMP = [];
              // 选择目的地之后，就筛选出对应的插头
              res.data.socketType.forEach((element) => {
                plugListTMP.push(
                  this.plugListOrigin.find((i) => i["name"] == element["name"])
                );
              });
              this.plugList = plugListTMP;
            });
          });

        }else{
          // 目的国存在，出发国不存在
          if (existedDestinationCountry && !existedLocationCountry){
            this.is404 = true
          }
        }
        console.log("URL segs", segs);
        if (hash === "#ComparisonResult_1670130578382") {
          setTimeout(this.compareBtn, 0);
        }
      }else{
        if(segs.length == 1){
          const existedDestinationCountry = this.countryNameList.includes(segs[0]);
          if(existedDestinationCountry){
            this.is404 = false
            this.destination = segs[0];
            this.selectDestination({ value: segs[0] }, false);
            let obj = this.countryList.find(item=>item.country_name == segs[0])
            get(`/article/?countryid=${obj.id}`).then(res => {
                  console.log('resss:',res)
                  this.listData = res.data
            });
            // 获取插座类型
            get("/pluglist/").then((res) => {
              console.log("getPluglist:", res.data);
              this.plugListOrigin = res.data;
              this.plugList = res.data;

              get(`/countryone/${segs[0]}/`).then((res) => {
                console.log("selectDestination: ", res.data);
                // 目的国家
                this.destination_country = res.data;

                let plugListTMP = [];
                // 选择目的地之后，就筛选出对应的插头
                res.data.socketType.forEach((element) => {
                  plugListTMP.push(
                    this.plugListOrigin.find((i) => i["name"] == element["name"])
                  );
                });
                this.plugList = plugListTMP;
              });
            });

          }else{
            this.is404 = true
          }
        }else{
          this.is404 = true
        }
      }
    }
    },
    //根据传进来的状态改变建议输入框的状态（展开|隐藏）
    changeStyle(status, className) {
      let dom = document.querySelectorAll(className);
      dom[0].style.display = status;
    },
  },
  mounted() {
    const { hash, pathname } = window.location;
    if(hash == '#destination'){
        setTimeout(() => {
          document.getElementById("destination").scrollIntoView()
        }, 1000);
      }
    // this.getDataAll7();
    this.checkCurrentURL();
    this.checkIsMobile();
    this.options = JSON.parse(localStorage.getItem("options"));
    if (!this.countryList || !this.options) {
      // 获取国家名字，作为下拉框选项
      get("/country/").then((res) => {
        if(res.data){
          console.log("countryList:", res.data);
          this.countryList = res.data;
          this.tableData = res.data;
          localStorage.setItem("countryList", JSON.stringify(res.data));
          // prepare options for cascade components
          // Mobile only
          if (this.isMobile) {
            console.log("@@@@@@@@@@@@@@@@")
            // [{ value: 'guide', label: 'Guide', children: [{ value: 'disciplines', label: 'Disciplines' }, ...]}]
            this.letters = Array.from(
              new Set(
                this.countryList.map((v) =>
                  v.country_name.slice(0, 1).toUpperCase()
                )
              )
            );
            this.options = [];
            this.letters.forEach((letter) => {
              const findList = this.countryList.filter((v) =>
                v.country_name.startsWith(letter)
              );
              this.options.push({
                label: letter,
                value: letter,
                children: findList.map((v) => ({
                  ...v,
                  label: v.country_name,
                  value: v.country_name,
                })),
              });
            });
            localStorage.setItem("options", JSON.stringify(this.options));
          }
        }
      });
    }

    // 微信内置浏览器浏览H5页面弹出的键盘遮盖文本框的解决办法
    window.addEventListener("resize", this.resizeFunc);
    // this.checkCurrentURL();

    // 获取插座类型
    // get("/pluglist/").then((res) => {
    //   console.log("getPluglist:", res.data);
    //   this.plugListOrigin = res.data;
    //   this.plugList = res.data;
      // check router
      // this.checkCurrentURL();
    // });

    // 获取广告
    get("/ads/").then((res) => {
      console.log("ads:", res.data);
      this.ads1 = res.data.filter((item) => item.type == "0");
      this.ads2 = res.data.filter((item) => item.type == "0");
      this.covers = res.data.filter((item) => item.type == "1");
      localStorage.setItem("covers", JSON.stringify(this.covers));
      this.adClearFlags1 = Array.from(res.data, (item) => ({
        [item.id]: false,
      }));
      this.adClearFlags2 = Array.from(res.data, (item) => ({
        [item.id]: false,
      }));
    });

    // this.checkCurrentURL();

  },
  beforeDestroy() {
    clearInterval(this.timer);
    // this.listData = 0
    this.is404 = false
  },
  destroyed() {
        this.listData = 0
    window.removeEventListener("resize", this.resizeFunc);
  },
};
</script>

<style lang="scss">
@import url("./static/css/reset.min.scss");
@import url("./static/css/dynamic.scss");

#img404{
  // background-color: #E8ECEE;
  // height:100vh;
  // width: 100vw;
  // margin: 0 auto;
  width:100%;
  height: 100vh;
}
#header {
  height: 360px;
  width: 100%;
  position: relative;
  color: #fff;
  background-size: cover;
  background-position: center right;

  > div {
    background-image: linear-gradient(to right, #003863, rgba(0, 56, 99, 0));
    height: inherit;
    cursor: pointer;
  }

  div.title {
    position: absolute;
    top: 100px;
    left: 80px;
    @media screen and (max-width: 600px) {
      left: 40px;
    }
    font-size: 40px;
    font-weight: 400;
  }

  div.sub-title {
    position: absolute;
    top: 180px;
    left: 80px;
    @media screen and (max-width: 600px) {
      width: 74%;
      left: 40px;
    }
    @media screen and (min-width: 600px) {
      width: 600px;
    }
    font-size: 18px;
    line-height: 26px;
  }
}

#body {
  // width: 88%;
  // margin: 0 auto;
  #selection {
    p {
      margin: 30px auto;
      text-align: center;
      font-size: 24px;
      line-height: 32px;
    }

    .section-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text {
          font-size: 22px;
          line-height: 1.6em;
          // margin: 0 20px;
        }
      }
      button {
        align-self: flex-end;
      }
      // pc屏幕
      @media screen and (min-width: 960px) {
        .el-autocomplete {
          .el-input {
            margin: 10px;
            .el-input__wrapper {
              margin: 0 8px;
              height: 40px;
              width: 200px;
            }
          }
        }
        button {
          margin: 10px;
          height: 40px;
          width: 200px;
        }
      }
      // 手机屏幕
      @media screen and (max-width: 960px) {
        .el-autocomplete {
          .el-input {
            margin: 10px 8px;
            .el-input__wrapper {
              margin: 0 8px;
              height: 40px;
            }
          }
        }
        button {
          margin: 10px 8px;
          height: 40px;
          width: 100%;
        }
      }
    }
  }

  #select-results {
    display: flex;
    flex-direction: column;

    .result-content {
      box-sizing: border-box;
      margin: 6px 0;
      font-size: 16px;
      line-height: 1.5em;

      ul {
        padding-inline-start: 2rem;
        list-style: circle;
      }

      > p {
        margin-bottom: 6px;
      }
      h2 {
        margin: 10px 0;
        font-size: 24px;
      }
      .content {
        section {
          font-size: 18px;
          line-height: 2em;
          text-indent: 2em;
        }
      }
      .section-list {
        display: flex;
        section {
          box-sizing: border-box;
          width: 100%;
        }
      }

      .comparison {
        h2 {
          margin: 10px 0;
          font-size: 24px;
        }
        padding: 10px;
        box-sizing: border-box;
        border: 1px solid #bbb;
      }

      img {
        display: block;
        margin: 0 auto;
        // 这是没输入目的国家时候显示的图片
        @media screen and (max-width: 600px) {
          width: 100%;
        }
      }

      .img-list {
        display: flex;
        justify-content: flex-start;
        .img {
          border: 1px solid #bbb;
          width: 200px;
          height: 200px;
          margin: 10px 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          box-sizing: border-box;
          line-height: 0.8em !important;
          text {
            margin-top: 3px;
            font-size: 12px;
            text-align: center;
          }
        }
        .img:hover {
          background-color: #f0d0d0;
        }
      }
    }
  }

  #comparison-result {
    display: flex;
    justify-content: space-around;
    flex-flow: row nowrap;
    padding-bottom: 1rem;
    border-bottom: 1px solid #666;
    .divider {
      margin-top: 84px;
      text-align: center;
    }
    .location-country-intro,
    .destination-country-intro {
      position: relative;
      p {
        font-weight: bold;
        border-left: 3px solid #7979ef;
        padding-left: 6px;
        height: 20px;
      }

      .country {
        position: absolute;
        right: 8px;
        top: 8px;
        width: auto;
        transform: scale(1.5);
      }

      .imglist {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 48px;

        > div {
          text-align: center;
        }
      }
    }

    @media screen and (max-width: 600px) {
      flex-flow: column nowrap;
      border-bottom: none;
      .divider {
        transform: rotateZ(90deg);
        width: 180px;
        margin: 40px auto;
      }
    }
  }

  #ad-section {
    .ad-wrapper {
      display: block;
      position: relative;
      height: 240px;
      background-size: cover;
      background-repeat: no-repeat;
      margin: 1rem 0;

      > a {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-image: linear-gradient(
          to right,
          #003863,
          rgba(0, 56, 99, 0)
        );
      }
    }
    .title {
      top: 64px;
      left: 32px;
      position: absolute;
      color: white;
      font-size: 32px;
      font-weight: bold;
    }
    .desp {
      line-height: 26px;
      top: 116px;
      left: 32px;
      color: white;
      position: absolute;
      font-size: 18px;
    }

    @media screen and (max-width: 600px) {
      .title,
      .desp {
        padding-right: 32px;
      }
      .desp {
        top: 148px;
      }
    }
    @media screen and (min-width: 600px) {
      .desp {
        top: 148px;
      }
    }

    .clear-icon {
      position: absolute;
      z-index: 2;
      top: 8px;
      right: 8px;
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      color: white;
      cursor: pointer;
      border: 1px solid #666;
    }
  }

  #pluglist {
    h2 {
      margin: 20px 0;
      font-size: 24px;
    }
    .el-collapse {
      --el-collapse-header-font-size: 16px;
      --el-collapse-content-font-size: 16px;
    }

    .el-collapse-item__wrap {
      img {
        width: 200px;
        height: 200px;
      }
    }

  }
}

.destination-list h2{
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 24px;
}

.plugItem{
  height: fit-content;
  .el-collapse-item__header{
    line-height: 2em;
    height: fit-content;
  }
}

#footer {
  height: 240px;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
}

.article {
  .el-collapse-item__header{
    line-height: 2em;
    height: fit-content;
  }
}

.navTitle{
  display: flex;
  .left{
    display: flex;
    flex-direction: column;
    h3{
      margin: 10px 0;
      font-weight: bold;
    }
  }
  .imglist{
    img{
      width: 150px;
      height: 150px;
      margin: 20px;
    }
}
}

.ant-list-lg .ant-list-item {
  padding-left: 0;
  padding-right: 0;
}
.ant-list-item-extra{
  img{
    // @media screen  and (max-width: 600px){
    //   width: 100%;
    // }
    @media screen  and (min-width: 600px){
      width: 272px;
    }
  }
}
.article{
  margin: 20px 0;
  font-size: 16px;
  .title{
    text-align: center;
    font-size: 22px;
  }
  .subtitle{
    margin: 10px 0;
    color: #bbb;
    font-size: 16px;
  }
  .desp{
    background-color: #FEF6FF;
    margin: 10px 0;
    padding: 5px;
    line-height: 1.4em;
  }
  .content{
    line-height: 1.8em;
  }

}

.destination-list{
    h2{
      margin: 20px 0;
    }
    .country-list{
      margin: 10rpx auto;
      p{
        margin:10px 0;
        padding: 10px;
        color: white;
        background-color: #003399;
      }
      .item{
        display: flex;
        flex-wrap: wrap;
        .country-item{
          cursor: pointer;
          font-size: 20px;
          width: 284px;
          margin: 5px ;
          display: flex;
          align-items: center;
          img{
            margin-right: 5px;
            width:32px;
            height:32px;
          }
        }
        .country-item:hover{
          text-decoration: underline;
          color: greenyellow;
          text-decoration-color: greenyellow;
        }
      }
    }
  }
  .target-list{
    h2{
      margin: 20px 0;
    }
    ul{
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      li{
        text-align: center;
        font-size: 24px;
        display: inline-block;
        // width: 40px;
        // height: 40px;
        line-height: 40px;
        cursor: pointer;
        // border: 1px solid red;
      }
      li:not(:last-child)::after{
        content:'|';
        margin-left:8px;
        margin-right:8px;
      }
      li:last-child::after{
          content:'';
          margin-left: 1px;
      }
      li:hover{
        color:blueviolet;
        text-decoration: underline;
      }
    }
  }
</style>
